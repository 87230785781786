import React from "react";
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { Link, useNavigate } from "react-router-dom";
import Metatag from "../../hooks/Metatag";

export default function HondaCarIndiaLimited() {
    return (
        <>
            <Header main_class="bg-header-home"></Header>

            <Metatag title="Honda Cars India Limited (HCIL)" keywords="Honda Cars India Limited (HCIL)" description="Honda Cars India Limited (HCIL)" />

            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-collaboration.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <Col md={6} lg={6} xxl={5}>
                            <h1 className="fw-semibold wow fadeInUp">Honda Cars India Limited (HCIL)</h1>
                        </Col>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid my-md-5 py-4">
                <Container className="px-0">
                    <div className="border-1 border-primary">
                        <div className="h-100 p-lg-5">
                            <div className="position-relative">
                                <Row>
                                    <Col md={5} lg={3} className="mb-4 mb-md-0 text-start">
                                        <img src={global.variables.strDomainUrl + "images/honda-logo.jpg"} className="img-fluid wow fadeIn" alt="Honda Cars India Limited (HCIL)" title="Honda Cars India Limited (HCIL)" />
                                    </Col>
                                    <Col md={7} lg={9} className="px-xl-4">
                                        <div className="mb-3">
                                            <p className="wow fadeInUp">IIT Bombay and Honda Cars India Limited (HCIL) signed a Master Joint Research Agreement, effective September 2, 2024, marking a five-year partnership facilitated by the Centre for Machine Intelligence and Data Science (CMInDS) at IIT Bombay. </p>
                                            <p className="wow fadeInUp">This collaboration is set to advance machine intelligence in the Indian mobility sector, addressing local challenges in road safety and autonomous driving amid India’s unique and complex traffic conditions.</p>
                                            <p className="wow fadeInUp">The project has potential global implications, with solutions developed for India potentially applicable in other regions.</p>
                                            
                                        </div>
                                    </Col>
                                </Row>
                                <hr className="my-5" />
                                <Row>
                                    <Col md={12}>
                                        <h5 className="mb-2">Our Objectives</h5>
                                        <ul className="mb-4">
                                            <li>To harness AI research for developing advanced driver assistance and autonomous driving technologies tailored to Indian roads.</li>
                                            <li>To apply Cooperative Intelligence (CI) in real-world mobility contexts, addressing the intricacies of India’s diverse traffic scenarios.</li>
                                            <li>To bridge the gap between academic research and industrial application by integrating academic insights and Honda's industry expertise.</li>
                                        </ul>

                                        {/* <h5 className="mb-2">Initiatives</h5> */}
                                        <b className="mb-2">Initial Research Focus</b>
                                        <p className="mb-2">The collaboration will begin with two key research initiatives focused on:</p>
                                        <ul className="mb-2">
                                            <li>Examining and adapting to the various road systems present in India.</li>
                                            <li>Enhancing AI’s ability to recognize and respond to diverse types of road and user conditions.</li>
                                        </ul>
                                        <p className="mb-4"><b>Collaboration and Talent Development</b><br/> IIT Bombay faculty and students will work directly with Honda experts on all phases of research, from planning and design to testing, creating an environment where academic knowledge merges with industry-driven development. Honda Japan will play a crucial role in supporting and advancing the research, alongside contributions from IIT graduates working in mobility intelligence since 2019.</p>

                                        <h5 className="mb-2">Expected Outcomes</h5>
                                        <ul className="mb-2">
                                            <li className="mb-2">Enhanced Safety and Mobility Solutions: <br/>Development of AI-powered technologies that improve road safety and enable advanced driver assistance systems (ADAS) and automated driving (AD) suited to India’s traffic landscape.</li>
                                            <li className="mb-2">Globally applicable Cooperative Intelligence Framework: <br/>The creation of adaptable AI solutions in Cooperative Intelligence, with research outcomes applicable beyond India.</li>
                                            <li className="mb-2">Strengthened Industry-Academia Link: <br/>A robust framework for promoting sustained collaboration between IIT Bombay and Honda through cooperative research theme setup and execution.</li>
                                        </ul>
                                        

                                        
                                        {/* <p className="mb-1">Outreach and cross-pollination of ideas: </p>
                                        <ul className="mb-4">
                                            <li>World-class research and publications in top-tier venues.</li>
                                            <li>Workshops and seminars on cutting-edge technology.</li>
                                        </ul> */}

                                        {/* <h5 className="mb-2 mt-5">People</h5>
                                        <Container className='px-0'>
                                            <h6 className="mb-2">Core Committee</h6>
                                            <Row className="row-cols-2 row-cols-md-4 row-cols-lg-6 g-3 g-lg-5">
                                                <Col className="text-center">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/ravi-gudi">
                                                        <img src={global.variables.strDomainUrl + "images/ravi-gudi.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Ravi Gudi" title="Prof. Ravi Gudi" />
                                                        <small>Prof. Ravi Gudi</small>
                                                    </Link>
                                                </Col>
                                                <Col className="text-center">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/d-manjunath">
                                                        <img src={global.variables.strDomainUrl + "images/d-manjunath.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. D. Manjunath" title="Prof. D. Manjunath" />
                                                        <small>Prof. D. Manjunath</small>
                                                    </Link>
                                                </Col>
                                                <Col className="text-center">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/sunita-sarawagi">
                                                        <img src={global.variables.strDomainUrl + "images/sunita-sarawagi.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Sunita Sarawagi" title="Prof. Sunita Sarawagi" />
                                                        <small>Prof. Sunita Sarawagi</small></Link>
                                                </Col>
                                                <Col className="text-center col">
                                                    <Link to="https://www.minds.iitb.ac.in/people/faculty/piyush-pandey">
                                                        <img src={global.variables.strDomainUrl + "images/piyush-pandey.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Piyush Pandey" title="Prof. Piyush Pandey" />
                                                        <small>Prof. Piyush Pandey</small>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className='mt-5 px-0'>

                                            <Row className="row-cols-2 row-cols-md-4 row-cols-lg-6 g-3 g-lg-5">
                                                <Col>
                                                    <h6 className="mb-2">PI</h6>
                                                    <div className="text-center">
                                                        <Link to="https://www.minds.iitb.ac.in/people/faculty/sunita-sarawagi">
                                                            <img src={global.variables.strDomainUrl + "images/sunita-sarawagi.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Sunita Sarawagi" title="Prof. Sunita Sarawagi" />
                                                            <small>Prof. Sunita Sarawagi</small></Link>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <h6 className="mb-2">Staff</h6>
                                                    <div className="text-center">
                                                        <img src={global.variables.strDomainUrl + "images/shilpa-balakrishnan.jpg"} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt="Prof. Sunita Sarawagi" title="Prof. Sunita Sarawagi" />
                                                        <small>Shilpa Balakrishnan (Program Manager)</small>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container> */}
                                        {/* <ul className="mb-4">
                                            <li><b>Core Committee:</b> Prof. Gudi, Prof. Manjunath, Prof. Sunita Sarawagi, Prof. Piyush Pandey</li>
                                            <li><b>PI:</b> Prof Sunita Sarawagi</li>
                                            <li><b>Staff:</b> Shilpa Balakrishnan (Program Manager)</li>
                                        </ul> */}
                                    </Col>
                                </Row>
                                <div className="d-flex flex-wrap justify-content-end gap-3 mt-4">
                                    <Link to="/collaboration" className="btn btn-primary px-4 py-3 fw-light text-uppercase rounded-0 fw-normal">
                                        Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel px-3">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl + "collaboration"}>Collaboration</Breadcrumb.Item>
                <Breadcrumb.Item active>Honda Cars India Limited (HCIL)</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
};